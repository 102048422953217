import {inject, Injectable} from "@angular/core";
import {ApiService} from "@app/services";
import {firstValueFrom, Observable} from "rxjs";
import {News} from "@app/interfaces";
import {PickedFile} from "@capawesome/capacitor-file-picker";
import {environment} from "@env/environment";
import {TimelineEntry} from "@interfaces/timeline-entry";
import {NewsRepository} from "@app/repositories/news.repository";
import {SettingsRepository} from "@app/repositories";

@Injectable({
  providedIn: 'root'
})
export class NewsService extends ApiService {
  private newsRepository: NewsRepository = inject(NewsRepository);
  private settingsRepository: SettingsRepository = inject(SettingsRepository);

  fetch(): Observable<News[]> {
    console.log(`${this.constructor.name}.fetch`);
    return this.get(`/news`);
  }

  async getOneFromApi(id: string): Promise<News> {
    console.log(`${this.constructor.name}.getOneFromApi`, id);
    const news = await firstValueFrom(this.get(`/news/${id}`));

    if (news) {
      this.newsRepository.addNews(news);
    }

    return news;
  }

  public async getAllFromApi(): Promise<News[]> {
    return firstValueFrom(this.fetch()).then((fetchedEntries: News[]) => {
      this.cacheNewsCollection(fetchedEntries);
      console.log(`${this.constructor.name}.getAllFromApi`, fetchedEntries.length);

      return fetchedEntries;
    });
  }

  private cacheNewsCollection(entries: News[]) {
    console.log(`${this.constructor.name}.cacheNews`);
    this.settingsRepository.setNewsUpdatedAt();
    this.newsRepository.updateNews(entries);
  }

  private async cacheNewsItem(entry: News) {
    console.log(`${this.constructor.name}.cacheNewsItem`, entry);
    this.newsRepository.addNews(entry);
  }

  async clearCache() {
    this.settingsRepository.removeNewsUpdatedAt();
    this.newsRepository.updateNews([]);
  }

  create(news: News): Observable<News> {
    console.log(`${this.constructor.name}.create`, news);
    return this.post('/news', news);
  }

  update(news: News): Observable<News> {
    console.log(`${this.constructor.name}.update`, news);
    return this.put(`/news/${news.id}`, news);
  }

  async fetchBySlug(placeSlug: string, newsSlug: string) {
    console.log(`${this.constructor.name}.fetchBySlug`);

    const newsEntry = await firstValueFrom(this.get(`/news/${newsSlug}`));
    if (newsEntry) {
      await this.cacheNewsItem(newsEntry);
      this.newsRepository.addNews(newsEntry);
    }

    return newsEntry;
  }

  uploadVideo(news: News, video: PickedFile): Observable<any> {
    console.log(`${this.constructor.name}.uploadVideo`, news.id, video);

    if (video.blob === undefined) {
      throw new Error('No video blob found');
    }

    const formData = new FormData();
    formData.append('video', video.blob, video.name);

    const url = `${environment.apiBaseUrl}/news/${news.id}/videos`;

    return this.http.post(url, formData, {reportProgress: true, observe: 'events'});
  }

  async toggleLike(entry: TimelineEntry): Promise<News> {
    console.log(`${this.constructor.name}.toggleLike`);
    const news = await firstValueFrom(this.post(`/news/${entry.id}/like`, {}));

    await this.cacheNewsItem(news);

    return news;
  }
}
